import React from "react";
import { useNavigate } from "react-router";

const MarketingCard = () => {
  const navigate = useNavigate();
  const cardsData = [
    {
      iconSrc: require("../assets/Images/marketingCard1.png"),
      title: "Design & Development",
      route: "/web-design-and-development/",
    },
    {
      iconSrc: require("../assets/Images/marketingCard2.png"),
      title: "Digital Marketing",
      route: "/digital-marketing-services/",
    },
    {
      iconSrc: require("../assets/Images/marketingCard3.png"),
      title: "E-Commerce Solutions",
      route: "/e-commerce-solution/",
    },
  ];

  const navigateToPage = (x) => {
    navigate(x);
    window.scrollTo(0, 0);
  };

  return (
    <div className="marketing-cards">
      <div className="container py-5 text-center">
        <div className="row">
          {cardsData?.map((x, index) => {
            const isNotLastItem = index !== cardsData?.length - 1;
            return (
              <div
                key={index}
                className={`col-md-4 ${
                  isNotLastItem ? "mb-5" : ""
                } mb-xl-0 mb-lg-0 mb-md-0`}
              >
                <img
                  src={x.iconSrc}
                  alt={`${index}_${x.iconName}`}
                  onClick={() => navigateToPage(x.route)}
                  style={{ cursor: "pointer" }}
                />
                <h5
                  className="m-0 pt-4 font-face-Bold"
                  onClick={() => navigateToPage(x.route)}
                >
                  {x.title}
                </h5>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MarketingCard;
