import React from "react";
import "./Footer.css";
import footerLogo from "../assets/Images/footerLogo.png";
import mailIcon from "../assets/Images/footerMail.png";
import locationIcon from "../assets/Images/footerAddress.png";
import facebookIcon from "../assets/Images/facebookIcon.png";
import linkdinIcon from "../assets/Images/LinkdinIcon.png";
import InstagramIcon from "../assets/Images/InstagramIcon.png";
import pinterestIcon from "../assets/Images/pinterestIcon.png";
import { useNavigate } from "react-router";

const Footer = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const goToAboutUs = () => {
    navigate("/about-us/");
    window.scrollTo(0, 0);
  };

  const goToWebDesignAndDevelopment = () => {
    navigate("/web-design-and-development/");
    window.scrollTo(0, 0);
  };

  const goToEcommerceSol = () => {
    navigate("/e-commerce-solution/");
    window.scrollTo(0, 0);
  };

  const goToDigitalMarketingServices = () => {
    navigate("/digital-marketing-services/");
    window.scrollTo(0, 0);
  };

  const goToPortfolio = () => {
    navigate("/portfolio/");
    window.scrollTo(0, 0);
  };

  const goToTermsAndConditions = () => {
    navigate("/termsandconditions/");
    window.scrollTo(0, 0);
  };

  const goToPrivacyPolicy = () => {
    navigate("/privacypolicy/");
    window.scrollTo(0, 0);
  };

  return (
    <div className="footer-main">
      <div className="container py-5 text-white">
        <div className="row">
          <div className="col-lg-5 col-md-6">
            <img
              className="web-logo"
              onClick={goToHome}
              src={footerLogo}
              alt="footerLogo"
            />
            <div className="mt-3 ms-0 ms-lg-2">
              <p className="m-0 font-face-Regular">
                Develop customized digital{" "}
                <span className="d-lg-block">
                  strategies tailored to the client's
                </span>
                goals and target audience.
              </p>
            </div>
          </div>

          <div className="col-lg-2 col-md-6">
            <h3 className="font-face-Bold mt-3 mt-lg-0 mt-md-0">Quick Links</h3>
            <div className="mt-4">
              <ul className="font-face-Regular">
                <li onClick={goToAboutUs}>About Us</li>
                <li onClick={goToPortfolio}>Portfolio</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 mt-3 mt-lg-0">
            <h3 className="font-face-Bold">Platforms</h3>
            <div className="mt-4">
              <ul className="font-face-Regular">
                <li onClick={goToDigitalMarketingServices}>
                  Digital Marketing
                </li>
                <li onClick={goToWebDesignAndDevelopment}>Web Design</li>
                <li onClick={goToEcommerceSol}>E-Commerce Solutions</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-3 mt-lg-0">
            <h3 className="text-white font-face-Bold">Contact</h3>
            <div className="d-flex align-items-center text-white mt-4 info-box">
              <img src={mailIcon} alt="mailIcon" />
              <div className="crs ms-2 font-face-Regular">
                <a
                  href="mailto:info@techno-rivals.com"
                  className="text-white text-decoration-none"
                >
                  info@techno-rivals.com
                </a>
              </div>
            </div>
            <div className="d-flex align-items-center text-white py-1">
              <img src={locationIcon} alt="mailIcon" />
              <div className="crs ms-2 font-face-Regular">
                <a className="text-white text-decoration-none">
                  Block 13 D 2, Karachi, Sindh
                </a>
              </div>
            </div>

            <div className="mt-4"></div>
            <div className="d-flex social-icons">
              <a
                href="https://www.facebook.com/profile.php?id=100091509381433"
                target="blank"
              >
                <img className="mx-2" src={facebookIcon} alt="facebookIcon" />
              </a>
              {/* <img className="mx-2" src={twitterIcon} alt="twitterIcon" /> */}
              <a
                href="https://pk.linkedin.com/company/techno-rivals"
                target="blank"
              >
                <img className="mx-2" src={linkdinIcon} alt="linkdinIcon" />
              </a>
              <a
                href="https://www.instagram.com/techno_rivals?igsh=MjRmemp6NjFtc3Zp&utm_source=qr"
                target="blank"
              >
                <img className="mx-2" src={InstagramIcon} alt="InstagramIcon" />
              </a>

              {/* <img className="mx-2" src={pinterestIcon} alt="pinterestIcon" /> */}
            </div>
          </div>
        </div>

        <div className="d-md-flex align-items-center justify-content-between mt-5">
          <span className="font-face-Regular">
            © techno rivals 2024 | All Rights Reserved
          </span>
          <div className="link-box font-face-Regular">
            <span
              className="mx-0 mx-lg-2 mx-md-2"
              onClick={goToTermsAndConditions}
              style={{ cursor: "pointer" }}
            >
              Terms & Conditions
            </span>
            <span
              className="mx-0 mx-lg-2 mx-md-2"
              style={{ cursor: "pointer" }}
              onClick={goToPrivacyPolicy}
            >
              Privacy Policy
            </span>
            {/* <span className="mx-0 mx-lg-2 mx-md-2"> Contact Us</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
