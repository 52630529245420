import "../App.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {
  Canvas,
  extend,
  useFrame,
  useLoader,
  useThree,
} from "react-three-fiber";
import circleImg from "../assets/circle.png";
import { Suspense, useCallback, useEffect, useMemo, useRef } from "react";
import AboutInfo from "../components/AboutInfo";
import ServicesInfo from "../components/ServicesInfo";
import PortfolioSection from "../components/PortfolioSection";
import Aos from "aos";
import "aos/dist/aos.css";
extend({ OrbitControls });

function CameraControls() {
  const {
    camera,
    gl: { domElement },
  } = useThree();

  const controlsRef = useRef();
  useFrame(() => controlsRef.current.update());

  return (
    <orbitControls
      ref={controlsRef}
      args={[camera, domElement]}
      autoRotate
      autoRotateSpeed={-0.2}
      enableZoom={false}
    />
  );
}

function Points() {
  const imgTex = useLoader(THREE.TextureLoader, circleImg);
  const bufferRef = useRef();

  let t = 0;
  let f = 0.002;
  let a = 3;
  const graph = useCallback(
    (x, z) => {
      return Math.sin(f * (x ** 2 + z ** 2 + t)) * a;
    },
    [t, f, a]
  );

  const count = 100;
  const sep = 3;
  let positions = useMemo(() => {
    let positions = [];

    for (let xi = 0; xi < count; xi++) {
      for (let zi = 0; zi < count; zi++) {
        let x = sep * (xi - count / 2);
        let z = sep * (zi - count / 2);
        let y = graph(x, z);
        positions.push(x, y, z);
      }
    }

    return new Float32Array(positions);
  }, [count, sep, graph]);

  useFrame(() => {
    t += 15;

    const positions = bufferRef.current.array;

    let i = 0;
    for (let xi = 0; xi < count; xi++) {
      for (let zi = 0; zi < count; zi++) {
        let x = sep * (xi - count / 2);
        let z = sep * (zi - count / 2);

        positions[i + 1] = graph(x, z);
        i += 3;
      }
    }

    bufferRef.current.needsUpdate = true;
  });

  return (
    <points>
      <bufferGeometry attach="geometry">
        <bufferAttribute
          ref={bufferRef}
          attachObject={["attributes", "position"]}
          array={positions}
          count={positions.length / 3}
          itemSize={3}
        />
      </bufferGeometry>

      <pointsMaterial
        attach="material"
        map={imgTex}
        color={0xe54100}
        size={0.8}
        sizeAttenuation
        transparent={false}
        alphaTest={0.5}
        opacity={1.0}
      />
    </points>
  );
}

function AnimationCanvas() {
  return (
    <Canvas
      colorManagement={false}
      camera={{ position: [10, 100, 0], fov: 75 }}
    >
      <Suspense fallback={null}>
        <Points />
      </Suspense>
      <CameraControls />
    </Canvas>
  );
}

function HomePage() {
  const tabData = {
    home: [
      {
        imgSrc: require("../assets/Images/WebDesign1.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign2.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign3.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign4.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign5.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign6.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign7.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign8.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign9.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
    ],
    "E-commerce Solutions": [
      {
        imgSrc: require("../assets/Images/ecommerceSolution1.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution2.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution3.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution4.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution5.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution6.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution7.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution8.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution9.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
    ],
    "Digital Marketing Solutions": [
      {
        imgSrc: require("../assets/Images/1.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/2.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/3.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/4.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/5.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/6.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/7.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/8.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/9.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
    ],
  };

  useEffect(() => {
    Aos.init({ duration: 800 });
  });
  return (
    <>
      <div className="anim">
        <Suspense fallback={<div>Loading...</div>}>
          <AnimationCanvas />
        </Suspense>
        <div className="container d-flex flex-column justify-content-center">
          <div className="head">
            <h1
              className="first font-face-Bold"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              One Stop Solution{" "}
            </h1>
            <h1
              className="second px-md-5 m-0 font-face-Bold"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              To <span className="text-theme">Business</span> Success!
            </h1>
          </div>
        </div>
      </div>

      <AboutInfo />
      <ServicesInfo />
      <PortfolioSection tabData={tabData} />
    </>
  );
}

export default HomePage;
