import React, { useState } from "react";
import "./PortfolioSection.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useLocation } from "react-router-dom";

const PortfolioSection = (props) => {
  const [activeTab, setActiveTab] = useState("home");
  const location = useLocation();

  let portfolioData = props?.tabData;

  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };

  const renderTabContent = () => {
    return portfolioData[activeTab]?.map((x, index) => (
      <div className="image-container col-md-4 px-0" key={index}>
        <div className="image-box">
          <img
            className="first-image img-fluid"
            src={x.imgSrc}
            alt={`FirstImage ${index}`}
          />
          <img
            className="img-fluid overlay-image"
            src={x.overlayImgSrc}
            alt={`OverlayImage ${index}`}
          />
        </div>
      </div>
    ));
  };

  return (
    <div className="portfolio-main">
      {location.pathname === "/" || location.pathname === "/portfolio/" ? (
        <div className="container py-3 p-lg-5 py-md-5 py-xl-5">
          <div>
            <p
              className="text-seond-theme fs-6 font-face-Bold"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              LATEST PORTFOLIO
            </p>
            <h1
              className="text-black font-face-Bold"
              data-aos="zoom-in"
              data-aos-duration="1100"
            >
              Your Partner In Digital Storytelling
            </h1>
            <p
              className="font-face-Regular"
              data-aos="zoom-in"
              data-aos-duration="1200"
            >
              Learn how Techno Rivals was involved in our client's digital
              transformation.
              <span className="d-lg-block">
                Read and View the work we've done for our clients.
              </span>
            </p>
          </div>

          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3 font-face-Bold"
            activeKey={activeTab}
            onSelect={handleTabSelect}
          >
            <Tab eventKey="home" title="Web Design & Development">
              <div className="row pt-5">{renderTabContent()}</div>
            </Tab>
            <Tab eventKey="E-commerce Solutions" title="E-commerce Solutions">
              <div className="row pt-5">{renderTabContent()}</div>
            </Tab>
          </Tabs>
        </div>
      ) : (
        <div className="container py-3 p-lg-5 py-md-5 py-xl-5">
          <h2 className="paragraph-underline font-face-Bold">
            Recent Projects
          </h2>
          <div className="row pt-3">{renderTabContent()}</div>
        </div>
      )}
    </div>
  );
};

export default PortfolioSection;
