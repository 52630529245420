import { React, useEffect } from "react";
import AboutUsHero from "../components/AboutUsHero";
import bgImage from "../assets/Images/ecommerceSolutionBg.jpg";
import CustomOverViewCard from "../components/CustomOverViewCard";
import ECommerceServices from "../components/ECommerceServices";
import PortfolioSection from "../components/PortfolioSection";
import Aos from "aos";
import "aos/dist/aos.css";

const ECommerceSolutionPage = () => {
  const bennerHeadingOne = "E-Commerce";
  const bennerHeadingTwo = "Solutions";
  const description =
    "Revolutionize your online business with our cutting-edge e-commerce solutions. Our tailored approach ensures your store stands out, offering seamless shopping experiences for your customers. From intuitive user interfaces to secure payment gateways, we handle it all. With advanced analytics, you'll gain insights to optimize sales. Whether you're a startup or an established brand, our e-commerce solutions empower you to thrive in the digital marketplace. Elevate your online presence today and watch your business grow!";

  const customOverViewHeadingOne = "E-Com Solutions";
  const customOverViewDescription =
    "The E-Commerce marketplace is constantly growing, requiring careful adaption and monitoring to see your business moving toward success.For this reason, we offer our clients comprehensive e-commerce solutions that help them to thrive in today's ever-moving digital world. From finding winning products to running your business, we've got it all covered!";
  const tabData = {
    home: [
      {
        imgSrc: require("../assets/Images/ecommerceSolution1.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution2.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution3.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution4.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution5.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution6.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution7.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution8.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution9.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
    ],
    "E-commerce Solutions": [
      // JSON data for the "E-commerce Solutions" tab
    ],
    "Digital Marketing Solutions": [
      {
        imgSrc: require("../assets/Images/1.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/2.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/3.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/4.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/5.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/6.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/7.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/8.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/9.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
    ],
  };
  useEffect(() => {
    Aos.init({ duration: 800 });
  });
  return (
    <div>
      <AboutUsHero
        bgImage={bgImage}
        bennerHeadingOne={bennerHeadingOne}
        bennerHeadingTwo={bennerHeadingTwo}
        description={description}
      />
      <CustomOverViewCard
        customOverViewHeadingOne={customOverViewHeadingOne}
        customOverViewDescription={customOverViewDescription}
      />
      <ECommerceServices />
      <PortfolioSection tabData={tabData} />
    </div>
  );
};

export default ECommerceSolutionPage;
