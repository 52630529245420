import React, { useRef } from "react";
import "./PopupContactForm.css";
import { IoIosClose } from "react-icons/io";
import emailjs from "@emailjs/browser";

const PopupContactForm = (props) => {
  const handleClose = () => props.setShowForm(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const emailInput = document.getElementById("emailPopUp");
    const nameInput = document.getElementById("fullnamePopUp");
    const messageInput = document.getElementById("messagePopUp");
    const subjectInput = document.getElementById("subjectPopUp");

    // if (isNaN(phoneInput.value)) {
    //   alert("Enter Only Numaric Value");
    //   return;
    // }

    // if (phoneInput.value.length > 15) {
    //   alert("Max 15 digits allowed");
    //   return;
    // }

    if (
      emailInput.value === "" ||
      messageInput.value === "" ||
      nameInput.value === "" ||
      subjectInput.value === ""
    ) {
      alert("Please enter all fields.");

      return;
    }

    emailjs
      .sendForm(
        "service_bmk0yzo",
        "template_3fqhbpm",
        form.current,
        "RJyAU3MUv59lnvGhe"
      )
      .then(
        (result) => {
          form.current.reset();
          window.alert("Email sent successfully!");
          props.setShowForm(false)
          // console.log(result);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="popup-container">
      <div className="close-popup text-end me-3" onClick={handleClose}>
        <IoIosClose size={30} />
      </div>
      <div className="popup-content">
        <div className="text-center">
          <h3>Send us a message</h3>
          <p>
            Reach out via our contact form to start transforming your digital
            presence and accelerating your business success today.
          </p>
        </div>
        <form
          role="form"
          id="contactForm"
          name="contact-form"
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="d-md-flex align-items-center justify-content-between mt-4">
            <div className="form-group">
              <label htmlFor="fullName">
                <small>Full Name</small>
              </label>
              <input
                className="mt-2 w-100"
                type="text"
                placeholder="Type Your Name"
                id="fullnamePopUp"
                name="fullname"
              />
            </div>
            <div className="form-group mt-4 mt-lg-0 mt-md-0">
              <label htmlFor="Email">
                <small>Email</small>
              </label>
              <input
                className="mt-2 w-100"
                type="email"
                placeholder="Type Your Email"
                id="emailPopUp"
                name="email"
              />
            </div>
          </div>

          <div className="form-group mt-4">
            <label htmlFor="Subject">
              <small>Subject</small>
            </label>
            <input
              className="mt-2 w-100"
              type="text"
              placeholder="Type Your Subject"
              id="subjectPopUp"
              name="subject"
            />
          </div>
          <div className="form-group mt-4">
            <label htmlFor="Message">
              <small>Message</small>
            </label>
            <textarea
              id="messagePopUp"
              name="message"
              rows={6}
              className="mt-2 w-100"
              type="text"
              placeholder="Type Your Message"
            />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button className="popup-btn">
              SEND MESSAGE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopupContactForm;
