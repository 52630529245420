import React from "react";

const ECommerceServices = () => {
  const data = [
    {
      serviceName: "Amazon FBA Solutions",
      serviceDescription:
        "When entering the E-Commerce marketplace, your safest bet is to go with Amazon FBA (Fulfillment By Amazon) Wholesale.Our team at Techno Rivals helps you leverage Amazon's dominating market, launch and run your business through our tailored process, and get your share of the Amazon market.",
      ImageSrc: require("../assets/Images/ECommerceServices1.png"),
    },
    // {
    //   serviceName: "Ebay",
    //   serviceDescription:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    //   ImageSrc: require("../assets/Images/ECommerceServices2.png"),
    // },
    {
      serviceName: "Shopify Dropshipping",
      serviceDescription:
        "Shopify is the leading e-commerce platform known for creating stunning online storefronts with a few clicks. Here at Techno Rivals, we help you incorporate Shopify elements in setting up your Shopify Dropshipping Business.We help you build and run your dropshipping from hunting the products to listing the products and beyond to ensure that we can smoothly operate your business, maximizing Shopify’s platform’s potential and building a passive income stream.",
      ImageSrc: require("../assets/Images/ECommerceServices3.png"),
    },
    {
      serviceName: "TikTok Shop White Label",
      serviceDescription:
        "TikTok has been one of the fastest-growing forms of media intake in recent years. TikTok used its increasing platform usage and influence to its advantage and decided to use the platform for e-commerce.It's an excellent platform for those who seek less competition but are not in a rush to make huge profits.As a team of experts in Techno Rivals, we deal with setup processes, marketing aspects, and product hunting, naming the few processes that go into setting up your TikTok Shop White Label Business. Once your business is set up and ready to run, we handle all the operations that go into running your TikTok White Label, ensuring you're earning profit in return for the investment you're making.",
      ImageSrc: require("../assets/Images/ECommerceServices4.png"),
    },
    {
      serviceName: "Walmart Dropshipping Services",
      serviceDescription:
        "For those who have already adapted to the e-commerce market and have at least two years of e-commerce experience, Walmart Dropshipping is a good option. To expand your e-commerce portfolio, you should start Walmart Dropshipping. Walmart is the leading retailer chain in the U.S., and we'll help you leverage this by using the dropshipping model.",
      ImageSrc: require("../assets/Images/ECommerceServices5.png"),
    },
  ];
  return (
    <div className="black-bg">
      <div className="container py-5 text-white">
        {data?.map((x, index) => {
          const isNotLastItem = index !== data?.length - 1;
          return (
            <div
              key={index}
              className={`row ${index % 2 === 0 ? "" : "flex-row-reverse"} ${
                isNotLastItem ? "mb-5" : ""
              }`}
            >
              <div className="col-md-6 d-flex flex-column justify-content-center">
                <p
                  className="font-face-Bold fs-3"
                  data-aos="zoom-in"
                  data-aos-duration="800"
                >
                  {x.serviceName}
                </p>
                <p
                  className="m-0 font-face-Regular"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  {x.serviceDescription}
                </p>
              </div>

              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center pt-3 pt-xl-0 pt-lg-0 pt-md-0">
                <img
                  className="img-fluid"
                  src={x.ImageSrc}
                  alt={`servicesImage ${index}`}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ECommerceServices;
