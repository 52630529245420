import React, { useRef } from "react";
import LineIcon from "../assets/Images/line.png";
import callIconSrc from "../assets/Images/contact-call.png";
import mapIconSrc from "../assets/Images/contact-map.png";
import "./ContactUs.css";
import emailjs from "@emailjs/browser";

const ContactUs = () => {
  const items = [
    // {
    //   callIconSrc: require("../assets/Images/contact-call.png"),
    //   mapIconSrc: require("../assets/Images/contact-map.png"),
    //   branchName: "Head Quarter",
    //   address: "Canton, New York",
    //   subAddress1: "3212 Ben Street,",
    //   subAddress2: "East Lansing",
    //   subAddress3: "48823",
    // },
    {
      callIconSrc: require("../assets/Images/contact-call.png"),
      mapIconSrc: require("../assets/Images/contact-map.png"),
      branchName: "Company Branch",
      address: "Block 13 D 2 Gulshan-e-Iqbal,",
      subAddress1: "Karachi, Pakistan",
      subAddress2: "Gulshan-e-Iqbal",
      subAddress3: "Karachi",
    },
  ];

  const navigateContact = () => {
    var url = "tel:03052420275";
    window.location.href = url;
  };

  const openMap = () => {
    // Construct the Google Maps URL
    const address =
      "Block 13 D 2 Gulshan-e-Iqbal, Karachi, Karachi City, Sindh";
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;

    // Open the Google Maps URL in a new tab
    window.open(googleMapsUrl, "_blank");
  };
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const emailInput = document.getElementById("email");
    const nameInput = document.getElementById("fullname");
    const messageInput = document.getElementById("message");
    const subjectInput = document.getElementById("subject");

    // if (isNaN(phoneInput.value)) {
    //   alert("Enter Only Numaric Value");
    //   return;
    // }

    // if (phoneInput.value.length > 15) {
    //   alert("Max 15 digits allowed");
    //   return;
    // }

    if (
      emailInput.value === "" ||
      messageInput.value === "" ||
      nameInput.value === "" ||
      subjectInput.value === ""
    ) {
      alert("Please enter all fields.");

      return;
    }

    emailjs
      .sendForm(
        "service_bmk0yzo",
        "template_3fqhbpm",
        form.current,
        "RJyAU3MUv59lnvGhe"
      )
      .then(
        (result) => {
          form.current.reset();
          window.alert("Email sent successfully!");
          console.log(result);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contact-form" id="contactSection">
      <div className="container py-5">
        {/* <div className="row px-0 px-xl-3 px-lg-3 px-md-3 justify-content-center"> */}
        <div className="row justify-content-center">
          {/* <div className="content-box col-xl-5 col-lg-6 px-lg-5 text-white"> */}
          <div className="content-box col-xl-5 col-lg-6 text-white">
            <div className="d-flex align-items-center">
              <p className="m-0 font-face-Bold">CONTACT</p>
              <img className="ms-2" src={LineIcon} alt="LineIcon" />
            </div>
            <h1 className="font-face-Bold">Get in touch</h1>
            <p className="m-0 py-2 font-face-Regular">
              Reach out via our contact form to start transforming your digital
              <span className="d-xl-block">
                presence and accelerating your business success today.
              </span>{" "}
            </p>

            {items?.map((x, index) => {
              return (
                <div key={index} className="inner-box1 py-4 mt-4 text-white">
                  <div className="d-flex align-items-center justify-content-around">
                    <div>
                      <h4 className="heading font-face-Bold">{x.branchName}</h4>
                      <div className="address-box">
                        <p className="font-face-Regular m-0">{x.address}</p>
                        <p className="font-face-Regular m-0">{x.subAddress1}</p>
                      </div>

                      <div className="top-spacing"></div>
                      {/* <button className="action-btn">
                        <img src={callIconSrc} alt="mapIcon" />
                        <span
                          className="ms-2 font-face-Regular"
                          onClick={navigateContact}
                        >
                          03052420275
                        </span>
                      </button> */}
                    </div>
                    <div>
                      {/* <p className="m-0 font-face-Regular">{x.subAddress1}</p>
                      <p className="m-0 font-face-Regular">{x.subAddress2}</p>
                      <p className="m-0 font-face-Regular">{x.subAddress3}</p> */}
                      <button className="action-btn">
                        <img src={callIconSrc} alt="mapIcon" />
                        <span
                          className="ms-2 font-face-Regular"
                          onClick={navigateContact}
                        >
                          03052420275
                        </span>
                      </button>

                      <div className="top-spacing"></div>
                      <button className="action-btn" onClick={openMap}>
                        <img src={mapIconSrc} alt="mapIcon" />
                        <span className="ms-2 font-face-Regular">VIEW MAP</span>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="form-box col-xl-6 col-lg-6 mt-xl-0 mt-lg-0 mt-5 text-white">
            <form
              role="form"
              id="contactForm"
              name="contact-form"
              ref={form}
              onSubmit={sendEmail}
            >
              <div className="inner-box py-4 px-3 px-md-5">
                <h4 className="text-accent font-face-Bold">
                  Send us a messsage
                </h4>
                <p className="font-face-Regular">
                  Reach out for inquiries, support, or feedback. We are here to
                  help and respond promptly to your communications.
                </p>

                <div className="first-box mt-5 d-md-flex align-items-center justify-content-between">
                  <div className=" form-group name">
                    <div className="text-accent font-face-Regular">
                      Full Name
                    </div>
                    <input
                      className="input-name mt-2 font-face-Regular form-control"
                      type="text"
                      id="fullname"
                      name="fullname"
                    />
                  </div>
                  <div className=" form-group email mt-4 mt-xl-0 mt-lg-0 mt-md-0">
                    <div className="text-accent font-face-Regular"> Email</div>
                    <input
                      className="input-email mt-2 font-face-Regular form-control"
                      type="email"
                      id="email"
                      name="email"
                    />
                  </div>
                </div>

                <div className="form-group mt-4">
                  <div className="text-accent font-face-Regular">Subject</div>
                  <input
                    className="input-subject mt-2 font-face-Regular form-control"
                    type="text"
                    id="subject"
                    name="subject"
                  />
                </div>
                <div className="form-group mt-4">
                  <div className="text-accent font-face-Regular">Message</div>

                  <textarea
                    className="mt-2 font-face-Regular form-control"
                    cols="30"
                    rows="5"
                    id="message"
                    name="message"
                  ></textarea>
                </div>

                <div className="text-center mt-3">
                  <button className="text-center global-btn-bg">
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
