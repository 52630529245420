import React from "react";

const IdentityHero = () => {
  const data = [
    {
      headingText: "Our Story",
      description:
        "Techno Rivals was founded on a vision to revolutionize digital marketing. Our journey began with a small, passionate team driven by the challenge of transforming how businesses engage with technology. We quickly distinguished ourselves by prioritizing innovation, quality, and customer-centric strategies, setting new standards in the industry. As we grew, our commitment to delivering measurable results and empowering businesses remained steadfast. Today, Techno Rivals stands as a beacon of excellence in digital marketing, continually evolving and pushing boundaries to help our clients thrive in an ever-changing digital world.",
    },
    {
      headingText: "Our Mission",
      description:
        "At Techno Rivals, our mission is to empower businesses by harnessing the transformative power of digital marketing. We strive to deliver innovative solutions that drive success and foster growth in a competitive digital landscape. Through strategic planning, expert insights, and cutting-edge technology, we aim to enhance online visibility, increase engagement, and maximize return on investment for our clients. Committed to excellence and integrity, we serve as a trusted partner in navigating the complexities of digital marketing, ensuring that every campaign we undertake is impactful, sustainable, and aligned with our clients’ goals.",
    },
  ];

  return (
    <div className="identity-hero">
      <div className="container py-5">
        <div className="row">
          {data?.map((x, index) => {
            const isLast = index >= data.length - 1;
            return (
              <div key={index} className={`col-md-6 ${isLast ? "" : "mb-4"}`}>
                <h4
                  className="paragraph-underline font-face-Bold"
                  data-aos="zoom-in"
                  data-aos-duration="800"
                >
                  {x.headingText}
                </h4>
                <p
                  className="m-0 pt-3 font-face-Regular"
                  data-aos="zoom-in"
                  data-aos-duration="1500"
                >
                  {x.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default IdentityHero;
