import React from "react";
import "./BrandsMarquee.css";
import Marquee from "react-fast-marquee";

const BrandsMarquee = () => {
  const languages = [
    {
      icon: require("../assets/Images/brandMarque/1.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/2.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/3.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/4.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/5.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/6.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/7.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/8.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/9.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/10.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/11.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/12.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/13.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/14.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/15.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/16.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/17.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/18.png"),
    },
    {
      icon: require("../assets/Images/brandMarque/19.png"),
    },
  ];

  const firstTenMarques = languages?.slice(0, 10);
  const restMarques = languages?.slice(10);

  return (
    <div className="lngBg text-white py-5">
      <div className="container-fluid">
        <Marquee direction="right" speed="30">
          {firstTenMarques?.map((x, index) => (
            <div key={index}>
              <img className="px-3" src={x.icon} alt="brandMarqueIcon" />
            </div>
          ))}
        </Marquee>

        <Marquee direction="right" speed="30">
          {restMarques?.map((x, index) => (
            <div className="pt-5" key={index}>
              <img className="px-3" src={x.icon} alt="brandMarqueIcon" />
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default BrandsMarquee;
