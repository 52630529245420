import React, { useEffect } from "react";
import AboutUsHero from "../components/AboutUsHero";
import bgImage from "../assets/Images/PortfolioPageBg.jpg";
import CustomOverViewCard from "../components/CustomOverViewCard";
import PortfolioSection from "../components/PortfolioSection";
import Aos from "aos";
import "aos/dist/aos.css";

const PortfolioPage = () => {
  const bennerHeadingOne = "Portfolio";
  const description =
    "Explore our portfolio showcasing our diverse range of creative projects and successful collaborations. From web design and branding to digital marketing campaigns, each project reflects our commitment to innovation and excellence. Our portfolio is a testament to our expertise in crafting unique and compelling experiences for our clients. Let our work speak for itself and inspire your next project. Discover what we can achieve together. Browse our portfolio today";

  const customOverViewHeadingOne = "Over View";
  const customOverViewDescription =
    "Welcome to Techno Rivals' portfolio page, where innovation meets competition to redefine the technological landscape. As a dynamic and forward-thinking company, Techno Rivals thrives on the spirit of rivalry, constantly pushing the boundaries of what's possible in the tech industry. Our portfolio serves as a testament to our dedication to excellence, showcasing a diverse array of projects spanning software development, hardware integration, and digital solutions. With a passionate team of experts at the helm, Techno Rivals is not just a company — we're a force to be reckoned with in shaping the future of technology. Explore our portfolio and witness the relentless pursuit of innovation that sets us apart in the competitive arena of tech.";
  const tabData = {
    home: [
      {
        imgSrc: require("../assets/Images/WebDesign1.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign2.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign3.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign4.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign5.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign6.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign7.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign8.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign9.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
    ],
    "E-commerce Solutions": [
      {
        imgSrc: require("../assets/Images/ecommerceSolution1.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution2.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution3.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution4.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution5.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution6.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution7.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution8.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/ecommerceSolution9.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
    ],
    "Digital Marketing Solutions": [
      {
        imgSrc: require("../assets/Images/1.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/2.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/3.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/4.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/5.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/6.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/7.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/8.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/9.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
    ],
  };

  useEffect(() => {
    Aos.init({ duration: 800 });
  });

  return (
    <div>
      <AboutUsHero
        bgImage={bgImage}
        bennerHeadingOne={bennerHeadingOne}
        description={description}
      />
      <CustomOverViewCard
        customOverViewHeadingOne={customOverViewHeadingOne}
        customOverViewDescription={customOverViewDescription}
      />
      <PortfolioSection tabData={tabData} />
    </div>
  );
};

export default PortfolioPage;
