import React from "react";
import "./AboutInfo.css";
import "aos/dist/aos.css";

const AboutInfo = () => {
  const aboutData = [
    {
      iconSrc: require("../assets/Images/aboutInfo1.png"),
      heading: "Crafting Digital Excellence",
      descriptionA: "Crafting digital excellence through innovative",
      descriptionB: "strategies at Techno Rivals.",
    },
    {
      iconSrc: require("../assets/Images/aboutInfo2.png"),
      heading: "Innovative Digital Solutions",
      descriptionA: "Innovative digital solutions that drive success",
      descriptionB: "and transform businesses globally.",
    },
    {
      iconSrc: require("../assets/Images/aboutInfo3.png"),
      heading: "Trusted Digital Partner",
      descriptionA: "Your trusted digital partner for the sustainable",
      descriptionB: "growth and market leadership.",
    },
  ];

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="about-info">
      <div className="container py-4">
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-center mb-5 mb-xl-0 mb-lg-0 mb-md-0">
            <p
              className="text-seond-theme fs-4 font-face-Bold"
              data-aos="zoom-in-up"
              data-aos-duration="1000"
            >
              KNOW ABOUT US
            </p>
            <h2
              className="text-black font-face-Bold"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              Unlocking The Power Of
            </h2>
            <h1
              className="unlocking-heading text-theme font-face-Bold"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              Digital Imagination
            </h1>
            <p
              className="text-third-theme pt-2 font-face-Regular"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              Unlocking the power of digital imagination to innovate, inspire,
              <span className="d-lg-block">
                and lead in a transformative era of digital marketing
                excellence.
              </span>
            </p>

            <button
              className="global-btn shadow-md"
              onClick={() => scrollToElement("contactSection")}
            >
              Contact Us
            </button>
          </div>

          <div className="col-md-6 d-flex flex-column justify-content-center align-items-end">
            {aboutData?.map((x, index) => {
              return (
                <div key={index} className="inner mb-5">
                  <div className="d-flex align-items-center">
                    <img className="img-fluid" src={x.iconSrc} alt="" />
                    <div className="ms-4">
                      <p
                        className="m-0 text-seond-black font-face-Bold"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        {x.heading}
                      </p>
                      <p
                        className="text-third-theme m-0 font-face-Regular"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        {x.descriptionA}
                      </p>
                      <p
                        className="text-third-theme m-0 font-face-Regular"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        {x.descriptionB}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutInfo;
