import React, { useEffect } from "react";
import AboutUsHero from "../components/AboutUsHero";
import bgImage from "../assets/Images/webdesignBg.jpg";
import StrategyOverview from "../components/StrategyOverview";
import PortfolioSection from "../components/PortfolioSection";
import CustomOverViewCard from "../components/CustomOverViewCard";
import Aos from "aos";
import "aos/dist/aos.css";

const WebDesignAndDevelopmentPage = () => {
  const bennerHeadingOne = "Web Design &";
  const bennerHeadingTwo = "Development";
  const description =
    "Welcome to our web design and development services! We specialize in creating stunning, user-friendly websites tailored to your needs. Our team of expert designers and developers ensures your site is not only visually appealing but also functional and responsive across all devices. From custom designs to e-commerce solutions, we've got you covered. Let us bring your vision to life online. Contact us today to discuss your project and get started on your digital journey";

  const customOverViewHeadingOne =
    "Website Design & Development Services by Techno Rivals";
  const customOverViewDescription =
    "Your website is often the first point of contact for many potential prospects. For this reason, your website needs to have an impactful first impression for the visitors on your website so that your prospects can take the desired action of your choice, be it buying a product or filling out a form.At Techno Rivals, we specialize in creating beautifully designed and responsive websites that encourage visitors to take action. Our team will execute your concepts and ideas to craft your dream website. With our team’s craft, you can express yourself through your uniquely identified and branded website.";

  const customOverViewHeadingTwo = "Our Approach";
  const customOverViewDescriptionTwo =
    "At Techno Rivals, our Web Design and Development department believes that great websites need to be visually appealing to visitors, user-friendly, and fully optimized for the best possible performance.For this reason, our team’s approach to design and development is wholly rooted in your personal, business, or professional goals. Whether you want your website built from scratch or revamped, our team consistently works towards your desired outcome.";
  const tabData = {
    home: [
      {
        imgSrc: require("../assets/Images/WebDesign1.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign2.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign3.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign4.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign5.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign6.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign7.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign8.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/WebDesign9.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
    ],
    "E-commerce Solutions": [
      // JSON data for the "E-commerce Solutions" tab
    ],
    "Digital Marketing Solutions": [
      {
        imgSrc: require("../assets/Images/1.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/2.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/3.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/4.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/5.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/6.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/7.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/8.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/9.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
    ],
  };
  useEffect(() => {
    Aos.init({ duration: 800 });
  });
  return (
    <div>
      <AboutUsHero
        bgImage={bgImage}
        bennerHeadingOne={bennerHeadingOne}
        bennerHeadingTwo={bennerHeadingTwo}
        description={description}
      />
      <CustomOverViewCard
        customOverViewHeadingOne={customOverViewHeadingOne}
        customOverViewDescription={customOverViewDescription}
        customOverViewHeadingTwo={customOverViewHeadingTwo}
        customOverViewDescriptionTwo={customOverViewDescriptionTwo}
      />
      <StrategyOverview />
      <PortfolioSection tabData={tabData} />
    </div>
  );
};

export default WebDesignAndDevelopmentPage;
