import React, { useEffect, useState } from "react";
import HomePage from "./pages/HomePage";
import ContactUs from "./components/ContactUs";
import Footer from "./layout/Footer";
import { Route, Routes } from "react-router-dom";
import NavigationBar from "./layout/NavigationBar";
import AboutUsPage from "./pages/AboutUsPage";
import WebDesignAndDevelopmentPage from "./pages/WebDesignAndDevelopmentPage";
import ECommerceSolutionPage from "./pages/ECommerceSolutionPage";
import DigitalMarketingServicesPage from "./pages/DigitalMarketingServicesPage";
import PortfolioPage from "./pages/PortfolioPage";
import PopupContactForm from "./components/PopupContactForm";
import TawkTo from "tawkto-react";
import whatsappimg from "./assets/Images/whatsapp.png";
import phoneimg from "./assets/Images/phone.png";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import AnimatedCursor from "react-animated-cursor";

const App = () => {
  const [showForm, setShowForm] = useState(false);
  const handleButton = () => {
    var url = "https://wa.me/+923052420275";
    window.open(url);
  };
  const navigateContact = () => {
    var url = "tel:03052420275";
    window.location.href = url;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowForm(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  let propertyId = "6428773631ebfa0fe7f5f406";
  let tawkId = "1gsv13bnc";
  useEffect(() => {
    var tawk = new TawkTo(propertyId, tawkId);

    tawk.onStatusChange((status) => {});
  }, [propertyId, tawkId]);
  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: "100px",
          left: "20px",
          zIndex: "9999",
          height: "50px",
          width: "50px",
        }}
      >
        <img
          alt="phone-img"
          src={phoneimg}
          style={{
            height: "60px",
            width: "60px",
            cursor: "pointer",
            borderRadius: "50px",
          }}
          onClick={navigateContact}
        />
      </div>

      <div
        style={{
          position: "fixed",
          bottom: "30px",
          left: "20px",
          zIndex: "9999",
          height: "50px",
          width: "50px",
        }}
      >
        <img
          alt="whatsapp-img"
          src={whatsappimg}
          style={{
            height: "60px",
            width: "60px",
            borderRadius: "50px",
            cursor: "pointer",
          }}
          onClick={handleButton}
        />
      </div>

      <div>
        {showForm ? <PopupContactForm setShowForm={setShowForm} /> : null}

        <NavigationBar />

        <Routes>
          <Route path="/" element={<HomePage />} exact />
          <Route path="/about-us/" element={<AboutUsPage />} />
          <Route
            path="/web-design-and-development/"
            element={<WebDesignAndDevelopmentPage />}
          />
          <Route
            path="/e-commerce-solution/"
            element={<ECommerceSolutionPage />}
          />
          <Route
            path="/digital-marketing-services/"
            element={<DigitalMarketingServicesPage />}
          />
          <Route path="/portfolio/" element={<PortfolioPage />} />
          <Route
            path="/termsandconditions/"
            element={<TermsAndConditionsPage />}
          />
          <Route path="/privacypolicy/" element={<PrivacyPolicyPage />} />
        </Routes>
        <ContactUs />
        <Footer />
      </div>
    </>
  );
};

export default App;
