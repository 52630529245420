import React, { useEffect } from "react";
import bgImage from "../assets/Images/privacypolicy.jpg";
import AboutUsHero from "../components/AboutUsHero";
import Aos from "aos";
import "aos/dist/aos.css";

const PrivacyPolicyPage = () => {
  const bennerHeadingOne = "Privacy And Policy";
  const description =
    "Our Privacy Policy outlines how we collect, use, and protect your personal information. By accessing or using our services, you agree to the terms outlined in this policy. We may update or modify this policy without prior notice, so please review it periodically. Your continued use of our services after any changes indicates your acceptance of the updated policy. For detailed information on our Privacy Policy, please visit our website or contact us directly.";

  const privacyPolicyContent = [
    {
      heading: "Information Collection",
      paragraph:
        "When you interact with our site, we collect personal information that you provide, such as name, email address, and contact details, along with transaction data, including purchase history and billing information. We also automatically collect data about your visit through cookies and analytics tools.",
    },
    {
      heading: "Use of Information",
      paragraph:
        "The information we collect is used to understand your needs and provide you with better service. Specifically, it helps us in managing your account, processing transactions, improving our website and products, and communicating with you about promotions, surveys, or other site features. We prioritize transparency in how your data is used.",
    },
    {
      heading: "Sharing of Information",
      paragraph:
        "We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, as long as those parties agree to keep this information confidential.",
    },
    {
      heading: "Data Security",
      paragraph:
        "Techno Rivals implements robust security measures to maintain the safety of your personal information. We use encryption, secure servers, and other physical and electronic measures to protect your data from unauthorized access, alteration, disclosure, or destruction. Your personal safety and security are paramount to us.",
    },
    {
      heading: "User Rights",
      paragraph:
        "You have the right to access, update, or delete the personal information we hold about you. You can also object to the processing of your data, restrict processing, or request that your data be ported to another service. To exercise these rights, please contact us directly.",
    },
    {
      heading: "Changes to This Policy",
      paragraph:
        "We may update this privacy policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy periodically to stay informed about how we are protecting the information we collect.",
    },
  ];

  useEffect(() => {
    Aos.init({ duration: 800 });
  });

  return (
    <div className="privacy-policy">
      <AboutUsHero
        bgImage={bgImage}
        bennerHeadingOne={bennerHeadingOne}
        description={description}
      />
      <div className="container py-5">
        <p
          className="m-0 font-face-Regular"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          Welcome to Techno Rivals. This privacy policy outlines how we collect,
          use, store, and protect your personal information. By using our
          website, you consent to the data practices described in this document.
          Our commitment is to protect your privacy and ensure the security of
          your personal information while offering you a seamless experience.
        </p>

        <div className="pt-3">
          {privacyPolicyContent?.map((x, index) => {
            return (
              <div className="pt-3" key={index}>
                <h2
                  className="font-face-Bold"
                  data-aos="zoom-in"
                  data-aos-duration="800"
                >
                  {x.heading}
                </h2>
                <p
                  className="m-0 font-face-Regular"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  {x.paragraph}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
