import React from "react";
import "./QuoteDisplay.css";

const QuoteDisplay = () => {
  return (
    <div className="quote-display">
      <div className="container py-5">
        <div className="text-center text-uppercase text-white font-face-Bold">
          <h1 className="m-0">Here Comes the quote</h1>
          <h1 className="m-0">or what ever you want</h1>
        </div>
      </div>
    </div>
  );
};

export default QuoteDisplay;
