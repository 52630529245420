import React from "react";
import { useLocation } from "react-router-dom";

const CustomOverViewCard = (props) => {
  const location = useLocation();
  return (
    <div
      className={
        location.pathname === "/e-commerce-solution/" ||
        location.pathname === "/digital-marketing-services/"
          ? "black-bg"
          : ""
      }
    >
      <div className="container py-5">
        <p
          className="paragraph-underline font-face-Bold fs-3"
          data-aos="zoom-in"
          data-aos-duration="1000"
        >
          {props?.customOverViewHeadingOne}
        </p>
        <p
          className="m-0 font-face-Regular"
          data-aos="zoom-in"
          data-aos-duration="1200"
        >
          {props?.customOverViewDescription}
        </p>
      </div>
      {props?.customOverViewHeadingTwo &&
      props?.customOverViewDescriptionTwo ? (
        <div className="container">
          <p
            className="paragraph-underline font-face-Bold fs-3"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            {props?.customOverViewHeadingTwo}
          </p>
          <p
            className="m-0 font-face-Regular"
            data-aos="zoom-in"
            data-aos-duration="1200"
          >
            {props?.customOverViewDescriptionTwo}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default CustomOverViewCard;
