import React, { useEffect } from "react";
import bgImage from "../assets/Images/terms&condition.jpg";
import AboutUsHero from "../components/AboutUsHero";
import Aos from "aos";
import "aos/dist/aos.css";

const TermsAndConditionsPage = () => {
  const bennerHeadingOne = "Terms And Conditions";
  const description =
    "Our website's Terms and Conditions outline the rules and regulations for using our site. By accessing or using our site, you agree to be bound by these Terms. We may update or modify these Terms without prior notice, so please review them periodically. Your continued use of the site after any changes indicates your acceptance of the modified Terms. For further details on our Terms and Conditions, please visit our website.";

  const content = [
    {
      heading: "User Responsibilities",
      paragraph:
        "Users must utilize the Techno Rivals website and its services within the bounds of all applicable laws and this agreement. You are responsible for maintaining the confidentiality of your account information and for all activities under your account. Any unauthorized use or breach of our services should be immediately reported to our support team.",
    },
    {
      heading: "Privacy Policy",
      paragraph:
        "Your privacy is paramount to us. Techno Rivals collects personal data to enhance our services and user experience. Our detailed privacy policy, accessible on our website, outlines the type of data we collect, its use, and the measures we take to protect your information.",
    },
    {
      heading: "Account and Security",
      paragraph:
        "Creating an account with Techno Rivals requires accurate and current information. Users are solely responsible for maintaining the confidentiality of their account details and for all activities under their account. The security of your account contributes to a safer user environment for all.",
    },
    {
      heading: "Intellectual Property Rights",
      paragraph:
        "All content provided on the Techno Rivals site, including text, graphics, logos, images, and software, is owned by or licensed to us and is protected by copyright and intellectual property laws. You may not use any content from our site without the express permission of Techno Rivals.",
    },
    {
      heading: "Termination and Suspension",
      paragraph:
        "Techno Rivals reserves the right to suspend or terminate your account if you violate our terms or engage in activities that breach legal statutes. Termination will result in the forfeiture and destruction of all information associated with your account.",
    },
    {
      heading: "Legal Compliance and Dispute Resolution",
      paragraph:
        "By using this website, you agree to comply with all local laws applicable to your use of the Techno Rivals website. Any disputes arising out of your use of the site will be resolved through arbitration, and you consent to the exclusive jurisdiction of our local courts.",
    },
  ];
  useEffect(() => {
    Aos.init({ duration: 800 });
  });
  return (
    <div className="terms-condition">
      <AboutUsHero
        bgImage={bgImage}
        bennerHeadingOne={bennerHeadingOne}
        description={description}
      />
      <div className="container py-5">
        <p
          className="m-0 font-face-Regular"
          data-aos="zoom-in"
          data-aos-duration="800"
        >
          Welcome to Techno Rivals. By accessing our website, you agree to be
          bound by these Terms and Conditions, which are designed to protect
          both you and our company. These terms are binding upon acceptance and
          govern your use of our services.
        </p>

        <div className="pt-3">
          {content?.map((x, index) => {
            return (
              <div className="pt-3" key={index}>
                <h2
                  className="font-face-Bold"
                  data-aos="zoom-in"
                  data-aos-duration="800"
                >
                  {x.heading}
                </h2>
                <p
                  className="m-0 font-face-Regular"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  {x.paragraph}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
