import React from "react";

const MarketingServices = () => {
  const serviceData = [
    {
      heading: "Search Engine Optimization (SEO)",
      description:
        "Improve your website's page ranking and visibility and drive traffic with our high-end SEO Services. From keyword research to on-page optimization, we’ll use our tailored methods to help you outrank your competition while climbing up the ranks in your search results.",
      imageSrc: require("../assets/Images/MarketingServices1.png"),
    },
    {
      heading: "Social Media Marketing",
      description:
        "Use our social media marketing services to interact with your target audience where they're present. From creating value-driven content to paid advertising to community management, our team at Techno Rivals is here to build a consistent brand image and presence on platforms like Instagram, Facebook, and LinkedIn.",
      imageSrc: require("../assets/Images/MarketingServices2.png"),
    },
    {
      heading: "Pay Per Click",
      description:
        "Reach a wide range of audiences in an instant with targeted PPC ad campaigns. Our team of PPC experts will help you craft and specifically optimize campaigns that drive qualified leads and traffic to your website, resulting in maximum return on investment (ROI).",
      imageSrc: require("../assets/Images/MarketingServices3.png"),
    },
    {
      heading: "Email Marketing",
      description:
        "Nurture leads through conversion-driven and segment-targeted email marketing campaigns. Our email marketing experts will create congruent email campaigns, from autoresponder emails to indoctrination emails to sales campaign emails, to encourage action from your email list and leads and drive results.",
      imageSrc: require("../assets/Images/MarketingServices4.png"),
    },
  ];
  return (
    <div className="marketing-services black-bg">
      <div className="container py-5 text-white">
        {serviceData?.map((x, index) => {
          const isNotLastItem = index !== serviceData?.length - 1;
          return (
            <div
              key={index}
              className={`row ${index % 2 === 0 ? "" : "flex-row-reverse"} ${
                isNotLastItem ? "mb-5" : ""
              }`}
            >
              <div className="col-md-6 d-flex flex-column justify-content-center">
                <p
                  className="font-face-Bold fs-3"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  {x.heading}
                </p>
                <p
                  className="m-0 font-face-Regular"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  {x.description}
                </p>
              </div>

              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center pt-3 pt-xl-0 pt-lg-0 pt-md-0">
                <img
                  className="img-fluid"
                  src={x.imageSrc}
                  alt={`servicesImage ${index}`}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MarketingServices;
