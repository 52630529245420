import React, { useEffect } from "react";
import "./AboutUsHero.css";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutUsHero = (props) => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  });
  return (
    <div
      style={{
        backgroundImage: `url(${props?.bgImage})`,
      }}
      className="about-banner"
    >
      <div className="container py-5 text-white">
        <div className="head-box text-start">
          <h1
            className="m-0 text-uppercase font-face-Bold"
            data-aos="zoom-in-up"
            data-aos-duration="800"
          >
            {props?.bennerHeadingOne}
            <span className="d-md-block"> {props?.bennerHeadingTwo}</span>
          </h1>
          <p
            className="pt-3 font-face-Italic"
            data-aos="zoom-in-up"
            data-aos-duration="1000"
          >
            {props?.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsHero;
