import React, { useState } from "react";
import "./ServiceInfo.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const ServicesInfo = () => {
  const slides = [
    {
      id: 1,
      imgSrc: require("../assets/Images/serviceInfo1.png"),
      heading: "Web Design &",
      subheading: "Development",
      description:
        "We use our high-end designers and developers to help you design and develop a modern and dynamic website you like. We ensure that your website visitors are captivated by what they see at first glance. We do this by crafting a responsive and user-responsive website that drives results while captivating your visitors.",
      bgcolor: "#E17055",
      route: "/web-design-and-development/",
    },

    {
      id: 2,
      imgSrc: require("../assets/Images/serviceInfo2.png"),
      heading: "E-Commerce",
      subheading: "Solutions",
      description:
        "At Techno Rivals, we specialize in building your e-commerce business from scratch on platforms such as Amazon FBA, Walmart Dropshipping, TikTok White Label, and Shopify Dropshipping. From product listings to fulfillment strategies, we'll cover it from A to Z.",
      bgcolor: "#1A1A1A",
      route: "/e-commerce-solution/",
    },
    {
      id: 3,
      imgSrc: require("../assets/Images/serviceInfo3.png"),
      heading: "Digital Marketing",
      subheading: "Services",
      description:
        "Using our Digital Marketing Services, improve your brand visibility and drive your audience to act in your desired direction. From SEO to Email Marketing Campaigns, we help you reach your unique targeted audience and desired goals. ",
      bgcolor: "#E17055",
      route: "/digital-marketing-services/",
    },
  ];
  const [activeSlide, setActiveSlide] = useState(0);
  const navigate = useNavigate();

  const [imageAnimationClass, setImageAnimationClass] = useState("");
  const [textAnimationClass, setTextAnimationClass] = useState("");

  console.log(setImageAnimationClass, setTextAnimationClass);

  const changeSlide = (index) => {
    let isNextSlide;

    if (index === 0 && activeSlide === slides.length - 1) {
      isNextSlide = true;
    } else if (index === slides.length - 1 && activeSlide === 0) {
      isNextSlide = false;
    } else {
      isNextSlide = index > activeSlide;
    }

    console.log(isNextSlide);

    // setImageAnimationClass(isNextSlide ? "animate-up" : "animate-down");
    // setTextAnimationClass(isNextSlide ? "animate-down" : "animate-up");
    setActiveSlide(index);
  };

  const goToPage = (route) => {
    // console.log(route);
    navigate(route);
    window.scrollTo(0, 0);
  };

  const isEven = activeSlide % 2 === 0;
  const currentSlide = slides[activeSlide];

  const imageContent = (
    <div className={`col-md-6 px-0 ${imageAnimationClass}`}>
      <img src={currentSlide.imgSrc} className="img-fluid" alt="ServiceImage" />
    </div>
  );

  const textContent = (
    <div
      className={`col-md-6 px-0 ${textAnimationClass}`}
      style={{ backgroundColor: currentSlide.bgcolor }}
    >
      <TextColumn slide={currentSlide} goToPage={goToPage} />
    </div>
  );

  return (
    <>
      <div className="Service container-fluid">
        <div className="container pb-3">
          <p
            className="text-black fs-6 font-face-Bold"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            LATEST SERVICE
          </p>
          <h1
            className="text-seond-theme font-face-Bold"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            Transforming ideas into digital{" "}
            <span className="d-lg-block">masterpieces</span>
          </h1>
        </div>
        <div className="row">
          {isEven ? imageContent : textContent}
          {isEven ? textContent : imageContent}
        </div>

        <div className="slider-dots">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`dot ${activeSlide === index ? "active" : ""}`}
              onClick={() => changeSlide(index)}
            ></span>
          ))}
        </div>
      </div>
      <div className="container Mobile-View ">
        <p className="text-black fs-6 font-face-Bold">LATEST SERVICE</p>
        <h1 className="text-seond-theme font-face-Bold">
          Transforming ideas into digital{" "}
          <span className="d-lg-block">masterpieces</span>
        </h1>
        {slides?.map((a, index) => {
          return (
            <div key={index} className="row mb-4">
              <div className="col-md-6">
                <img
                  className="img-fluid"
                  src={a.imgSrc}
                  alt={`FirstImage ${index}`}
                />
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center mt-2 text-center font-face-Bold">
                <h1>
                  {a.heading} {a.subheading}
                </h1>
                <p className="font-face-Regular">{a.description}</p>
                <button
                  className="global-btn shadow-md"
                  onClick={() => goToPage(a.route)}
                >
                  Explore More
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const TextColumn = ({ slide, goToPage }) => {
  return (
    <div className="service-content text-end">
      <h4 className="font-face-Bold">{slide.heading}</h4>
      <h1
        className={
          slide?.bgcolor === "#1A1A1A"
            ? "content-subheading text-theme font-face-Bold"
            : "content-subheading text-black font-face-Bold"
        }
      >
        {slide.subheading}
      </h1>

      <p className="content-description font-face-Regular">
        {slide.description}
      </p>
      <button
        className="global-btn shadow-md"
        onClick={() => goToPage(slide.route)}
      >
        {/* <BsFillPlusCircleFill /> */}
        Explore More
      </button>
    </div>
  );
};

export default ServicesInfo;
