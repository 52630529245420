import React, { useEffect } from "react";
import AboutUsHero from "../components/AboutUsHero";
import bgImage from "../assets/Images/DigitalMarketingBg.jpg";
import CustomOverViewCard from "../components/CustomOverViewCard";
import MarketingServices from "../components/MarketingServices";
import PortfolioSection from "../components/PortfolioSection";
import Aos from "aos";
import "aos/dist/aos.css";
import BrandsMarquee from "../components/BrandsMarquee";

const DigitalMarketingServicesPage = () => {
  const bennerHeadingOne = "Digital Marketing";
  const bennerHeadingTwo = "Solutions";
  const description =
    "Revolutionize your online business with our cutting-edge e-commerce solutions. Our tailored approach ensures your store stands out, offering seamless shopping experiences for your customers. From intuitive user interfaces to secure payment gateways, we handle it all. With advanced analytics, you'll gain insights to optimize sales. Whether you're a startup or an established brand, our e-commerce solutions empower you to thrive in the digital marketplace. Elevate your online presence today and watch your business grow!";

  const customOverViewHeadingOne = "Core Marketing Strategies";
  const customOverViewDescription =
    "In today’s era, having a solid online presence in the digital landscape is essential for success in every field.At Techno Rivals, we offer comprehensive digital marketing services aimed at helping businesses grow their reach toward their targeted audience, drive traffic toward their social media channels, and convert leads into customers.From Search Engine Optimization to running your Social Media Marketing Campaigns and everything in between, we have the resources to elevate your online and digital presence.";

  const customOverViewHeadingTwo = "Our Approach";
  const customOverViewDescriptionTwo =
    "At Techno Rivals, what makes digital marketing effective is more than just driving traffic to a specific social media platform; it's about building a connection with your potential customers in meaningful and value-driven touchpoints.For this reason, our digital marketing methods are deeply rooted in understanding your business goals and your unique audience first and then learning about the ever-changing competitive space. Whether your goal is to generate leads or build your brand's awareness from scratch, we devise a uniquely customized strategy to deliver your desired digital goals.";
  const tabData = {
    home: [
      {
        imgSrc: require("../assets/Images/1.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/2.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/3.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/4.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/5.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/6.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/7.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/8.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/9.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
    ],
    "E-commerce Solutions": [
      // JSON data for the "E-commerce Solutions" tab
    ],
    "Digital Marketing Solutions": [
      {
        imgSrc: require("../assets/Images/1.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/2.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/3.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/4.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/5.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/6.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/7.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/8.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
      {
        imgSrc: require("../assets/Images/9.png"),
        overlayImgSrc: require("../assets/Images/hover.png"),
      },
    ],
  };

  useEffect(() => {
    Aos.init({ duration: 800 });
  });
  return (
    <div>
      <AboutUsHero
        bgImage={bgImage}
        bennerHeadingOne={bennerHeadingOne}
        bennerHeadingTwo={bennerHeadingTwo}
        description={description}
      />
      <CustomOverViewCard
        customOverViewHeadingOne={customOverViewHeadingOne}
        customOverViewDescription={customOverViewDescription}
        customOverViewHeadingTwo={customOverViewHeadingTwo}
        customOverViewDescriptionTwo={customOverViewDescriptionTwo}
      />

      <MarketingServices />
      <BrandsMarquee />
      {/* <PortfolioSection tabData={tabData} /> */}
    </div>
  );
};

export default DigitalMarketingServicesPage;
