import React from "react";

const StrategyOverview = () => {
  const overviewData = [
    {
      imageSrc: require("../assets/Images/Strategy1.png"),
      title: "1. Project Discovery and Research",
      description:
        "In the initial phase, our team delves into understanding your business, objectives, and target audience. We conduct thorough research to uncover essential insights that drive the creation of a strategic foundation for your project. This ensures that our approach is perfectly aligned with your business needs.",
    },
    {
      imageSrc: require("../assets/Images/Strategy2.png"),
      title: "2. Planning and Strategy",
      description:
        "Following discovery, we develop a comprehensive plan outlining key project milestones, timelines, and resources. Our strategy focuses on aligning technology with business goals to ensure a smooth workflow. This stage sets the path for a successful project by defining clear, actionable steps.",
    },
    {
      imageSrc: require("../assets/Images/Strategy3.png"),
      title: "3. Wireframing and Prototyping",
      description:
        "Our design team creates detailed wireframes and prototypes, providing a visual blueprint of your website’s architecture. This process helps to visualize the user journey and make iterative adjustments. It’s a crucial step for aligning the final design with user needs and project goals.",
    },
    {
      imageSrc: require("../assets/Images/Strategy4.png"),
      title: "4. Visual Design",
      description:
        "At this stage, our designers bring your digital presence to life with compelling aesthetics tailored to your brand identity. We focus on creating engaging and intuitive designs that enhance user experience and foster engagement. Visual appeal is married with functionality to ensure your site stands out.",
    },
    {
      imageSrc: require("../assets/Images/Strategy5.png"),
      title: "5. Development",
      description:
        "Our development team transforms designs into functional, efficient websites using the latest technologies. We build with scalability and security in mind, ensuring your website performs seamlessly across all devices and platforms. This phase turns the conceptual framework into a dynamic digital solution.",
    },
    {
      imageSrc: require("../assets/Images/Strategy6.png"),
      title: "6. Deployment",
      description:
        "After development, your website is ready for launch. Our team meticulously prepares for deployment, ensuring all technical aspects are polished and optimized. We manage the launch process to ensure a smooth transition to live operations, minimizing downtime and potential issues.",
    },
    {
      imageSrc: require("../assets/Images/Strategy7.png"),
      title: "7. Testing and Quality Assurance",
      description:
        "Quality assurance is integral to our process. We conduct rigorous testing to ensure your site is free from bugs and meets all performance benchmarks. Our QA team works to guarantee that every element functions perfectly and that the end product is of the highest quality before going liv",
    },
  ];
  return (
    <div className="stratergy-overview">
      <div className="container py-5">
        <div className="row">
          {overviewData?.map((x, index) => {
            const isNotLastItem = index !== overviewData.length - 1;
            return (
              <div
                key={index}
                className={`col-md-4 ${isNotLastItem ? "mb-5" : ""}`}
              >
                <div>
                  <img className="img-fluid" src={x.imageSrc} alt="imageSrc" />
                  <p
                    className="text-seond-theme font-face-Bold pt-4"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    {x.title}
                  </p>
                  <p
                    className="lh-sm m-0 font-face-Regular"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    {x.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StrategyOverview;
