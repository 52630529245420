import React, { useEffect } from "react";
import AboutUsHero from "../components/AboutUsHero";
import bgImage from "../assets/Images/aboutBanner.png";
import IdentityHero from "../components/IdentityHero";
import QuoteDisplay from "../components/QuoteDisplay";
import MarketingCard from "../components/MarketingCard";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutUsPage = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  });
  const bennerHeadingOne = "Easy to get";
  const bennerHeadingTwo = "your job done";
  const description =
    "Get your job done easily with our streamlined services. We prioritize simplicity and efficiency, ensuring a hassle-free experience for our clients. Whether it's web design, digital marketing, or e-commerce solutions, we're here to make the process smooth and straightforward. Our team of experts is dedicated to delivering results quickly and efficiently. Experience the ease of working with us and achieve your goals without stress. Get started today and see the difference for yourself.";
  return (
    <div>
      <AboutUsHero
        bgImage={bgImage}
        bennerHeadingOne={bennerHeadingOne}
        bennerHeadingTwo={bennerHeadingTwo}
        description={description}
      />
      <IdentityHero />
      <QuoteDisplay />
      <MarketingCard />
    </div>
  );
};

export default AboutUsPage;
