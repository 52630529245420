import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./NavigationBar.css";
import {
  FaFacebookF,
  FaLinkedin,
  FaPinterestP,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";
import AppLogo from "../assets/Images/logo.png";
import { useNavigate } from "react-router-dom";

const NavigationBar = () => {
  const navigate = useNavigate();
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [isNavbarFixed, setNavbarFixed] = useState(false);

  const closeOffcanvas = () => {
    setIsOffcanvasOpen(false);
  };

  const navLinks = [
    {
      routeName: "home",
      routePath: "/",
    },
    {
      routeName: "about us",
      routePath: "/about-us/",
    },
    {
      routeName: "web design & development",
      routePath: "/web-design-and-development/",
    },
    {
      routeName: "e-commerce solutions",
      routePath: "/e-commerce-solution/",
    },
    {
      routeName: "Digital Marketing Services",
      routePath: "/digital-marketing-services/",
    },
    {
      routeName: "portfolio",
      routePath: "/portfolio/",
    },
  ];

  const handleItemClick = (route) => {
    navigate(route);
    setIsOffcanvasOpen(!isOffcanvasOpen);
    window.scrollTo(0, 0);
  };

  const goToHome = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 100;

      if (scrollPosition > threshold) {
        setNavbarFixed(true);
      } else {
        setNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar
        className={`${isNavbarFixed ? "fixed-top bg-white" : ""}`}
        key="false"
        expand="false"
      >
        <Container>
          <Navbar.Brand onClick={() => goToHome()} className="pointer">
            <img className="nav-logo" src={AppLogo} alt="AppLogo" />
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={() => setIsOffcanvasOpen(!isOffcanvasOpen)}
            aria-controls={`offcanvasNavbar-expand-false`}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-false`}
            aria-labelledby={`offcanvasNavbarLabel-expand-false`}
            placement="end"
            show={isOffcanvasOpen}
            onHide={closeOffcanvas}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                className="pointer"
                id={`offcanvasNavbarLabel-expand-false`}
              >
                {/* <img src={AppLogo} alt="AppLogo" /> */}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="header-main">
              <Nav className="mx-auto">
                <div className="container mx-auto">
                  <div className="header-links">
                    {navLinks?.map((x, index) => {
                      return (
                        <div key={index}>
                          <h2
                            onClick={() => handleItemClick(x.routePath)}
                            className="mb-3 mb-lg-4 mx-0 mx-lg-3 mx-md-4 font-face-Bold"
                          >
                            {x.routeName}
                          </h2>
                        </div>
                      );
                    })}
                  </div>

                  <div className="hide-box"></div>
                  <div className="header-icons">
                    <a
                      href="https://www.facebook.com/profile.php?id=100091509381433"
                      target="blank"
                    >
                      <FaFacebookF color="#000000" className="mx-4" size={20} />
                    </a>
                    {/* <FaTwitter color="#000000" className="mx-4" size={20} /> */}
                    <a
                      href="https://pk.linkedin.com/company/techno-rivals"
                      target="blank"
                    >
                      <FaLinkedin color="#000000" className="mx-4" size={20} />
                    </a>
                    <a
                      href="https://www.instagram.com/techno_rivals?igsh=MjRmemp6NjFtc3Zp&utm_source=qr"
                      target="blank"
                    >
                      <FaInstagram color="#000000" className="mx-4" size={20} />
                    </a>
                    {/* <FaPinterestP color="#000000" className="mx-4" size={20} /> */}
                  </div>
                </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBar;
